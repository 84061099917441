import React from 'react';
import Alert from 'react-bootstrap/Alert';


export default function SuccessBanner() {
  return (
    <Alert key="success" variant="success">
      Form Successfully submitted! We'll get in contact with you shortly.
    </Alert>
  )
}
