import React from 'react';

export default function Auditing() {
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/audit.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Auditing</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb"> 
                  <li className="breadcrumb-item"><a href="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</a></li>
                  <li className="breadcrumb-item"><a href="/our-services" style={{ color: '#fff', textDecoration: 'none' }}>Services</a></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Auditing</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="p-b-10" style={{ paddingBottom: '10px !important', minHeight: '10vh' }}>
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-3 pb-3 mx-auto">
              <div className="heading-text heading-section text-center" style={{ marginBottom: '30px'}}>
                <img className="icons img-fluid pb-4" src="/assets/images/analyze2.svg" alt="audit" />
              </div>
            </div>
            <div className="col-lg-9">
              <h3 
                style={{ 
                  fontSize: '1.7857em',
                  lineHeight: '1.5em',
                  fontWeight: '500',
                  letterSpacing: '0.5px',
                  margin: 0,
                  color: '#1f1f1f',
                  marginBottom: '12px'
                }}
              >
                Auditing
              </h3>
              <span
                style={{
                  fontSize: '1.25rem',
                  fontWeight: '400',
                  color: '#484848',
                  lineHeight: '1.65714em'
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Mauris augue neque gravida in fermentum. Nec nam aliquam sem et tortor consequat id porta. At erat pellentesque adipiscing commodo. Facilisi morbi tempus iaculis urna. Nibh venenatis cras sed felis eget velit. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Nibh cras pulvinar mattis nunc. Vestibulum lorem sed risus ultricies tristique nulla aliquet. Sit amet porttitor eget dolor morbi non arcu risus quis. Tristique risus nec feugiat in fermentum posuere urna. Ultricies lacus sed turpis tincidunt id aliquet risus. Et sollicitudin ac orci phasellus egestas. Condimentum id venenatis a condimentum vitae sapien pellentesque.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
