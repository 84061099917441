import React from 'react';
import { Link } from 'react-router-dom';

import { leaderShipData } from '../data/data';

import './leadership.css';

export default function Leadership() {
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/women.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div 
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Leadership</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Leadership</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="page-content">
        <div className="container">
          <div className="heading-text heading-section text-center pb-4">
            <h2>
              Our Team
            </h2>
            <p>Learn about the people providing solutions to your complex issues</p>
          </div>
          <div className="row team-members m-b-40" align="center" style={{ alignItems: "center", alignContent: "center", justifyContent: "center", textAlign: "center" }}>
            {leaderShipData.leadershipRow1.map((l, k) => (
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={k}>
                <div className="team-member">
                  <div className="team-image">
                    <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`} reloadDocument>
                      {console.log(l.leaderId)}
                      <img src={l.image} alt="team_member" />
                    </Link>
                  </div>
                  <div className="team-desc">
                    <h2>
                      <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`}>{l.name}</Link>
                    </h2>
                    <h3 className="leader-name">
                      <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`}>{l.position}</Link>
                    </h3>
                    <div className="align-center pt-4">
                      <Link className="btn btn-slide btn-light" to={l.linkedin}>
                        <i className="fab fa-linkedin-in fa-fw-l"></i>
                      </Link>

                      <a className="btn btn-slide btn-light" href={`mailto:${l.email}`}>
                        <i className="fa-regular fa-envelope fa-fw-l"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
{/*           
          <div className="row team-members m-b-40" align="center">
            {leaderShipData.leadershipRow2.map((l, k) => (
              <div className="col-lg-6" key={k}>
                <div className="team-member">
                  <div className="team-image">
                    <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`}>
                      <img src={l.image} alt="team_member" />
                    </Link>
                  </div>
                  <div className="team-desc">
                    <h2>
                      <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`}>{l.name}</Link>
                    </h2>
                    <h3>
                      <Link to={`/our-company/leadership/leader-profile/${l.leaderId}`}>{l.position}</Link>
                    </h3>
                    <div className="align-center pt-4">
                      <Link className="btn btn-slide btn-light" to={l.linkedin}>
                        <i className="fab fa-linkedin-in fa-fw-l"></i>
                      </Link>

                      <a className="btn btn-slide btn-light" href={`mailto:${l.email}`}>
                        <i className="fa-regular fa-envelope fa-fw-l"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </section>
    </>
  )
}
