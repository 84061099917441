import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import "bootstrap/dist/css/bootstrap.min.css";

import './appbar.css';

function Appbar() {
  const [stickyTop, setStickyTop] = useState(false);
  const [stickyActive, setStickyActive] = useState(false);
  const navRef = useRef();
  const navRefActive = useRef();

  navRef.current = stickyTop;
  
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;

      if (navRef.current !== show) {
        setStickyTop(show);
      } 
    };
    document.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, []);

  navRefActive.current = stickyActive;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;

      if (navRefActive.current !== show) {
        setStickyActive(show);
      } 
    };
    document.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" style={{ backgroundColor: "white", paddingTop: "20px", paddingBottom: "20px" }}>
      <Container>
        <Navbar.Brand href="/">
          <img src="/assets/images/logo4.png"  alt="elite" width="40" height="40" style={{ marginRight: "20px" }} />
          <h4 className="logo-name" style={{ fontFamily: "'Bruno Ace SC', cursive", color: "#5d79fe" }}>Elite Consulting</h4>
        </Navbar.Brand>
        <Navbar.Toggle style={{ }} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/" style={{ color: "#5d79fe", fontFamily: "'Comme', sans-serif" }}>Home</Nav.Link>
            <NavDropdown title={<span style={{ color: "#5d79fe", fontFamily: "'Comme', sans-serif" }}>Our Company</span>} id="collasible-nav-dropdown" style={{ color: "#5d79fe !important" , textDecoration: "none" }}>
              <NavDropdown.Item href="/our-company" style={{ color: "#5d79fe", fontFamily: "'Comme', sans-serif" }}>About Our Company</NavDropdown.Item>
              <NavDropdown.Item href="/our-company/leadership" style={{ color: "#5d79fe", fontFamily: "'Comme', sans-serif" }}>
                Leadership
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link eventKey={2} href="/contact-us" style={{ color: "#5d79fe", fontFamily: "'Comme', sans-serif" }}>
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
{/* <>
  <nav id="header" className="fixed-top navbar-light bg-light">
    <div className="header-inner">
      <div className="container-fluid" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <div id="logo">
          <a className="navbar-brand" href="/">
            <img src="/assets/images/logo4.png" className="img-fluid" alt="elite" style={{ verticalAlign: 'middle', borderStyle: 'none', width: "20%" }} />
          </a>
        </div>
        <div id="main-menu-trigger">
          <a className="lines-button x"> 
            <span className="lines"></span>
          </a>
        </div>
        <div id="main-menu">
          <nav style={{ float: 'right' }}>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="nav-item dropdown">
                <span className="dropdown-arrow"></span>
                <Link to="/our-company">Our Company</Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/our-company" className="dropdown-item" reloadDocument>About Our Company</Link>
                  </li>
                  <li>
                    <Link to="/our-company/leadership" className="dropdown-item" reloadDocument>Leadership</Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item dropdown">
                <span className="dropdown-arrow"></span>
                <Link to="/our-services">Our Services</Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/our-services/security-management" className="dropdown-item">Security Management</Link>
                  </li>
                  <li>
                    <Link to="/our-services/compliance" className="dropdown-item">Compliance</Link>
                  </li>
                  <li>
                    <Link to="/our-services/incident-response-and-forensic-services" className="dropdown-item">Incident Response and Forensic Services</Link>
                  </li>
                  <li>
                    <Link to="/our-services/auditing" className="dropdown-item">Auditing</Link>
                  </li>
                  <li>
                    <Link to="/our-services/penetration-testing" className="dropdown-item">Penetration Testing</Link>
                  </li>
                  <li>
                    <Link to="/our-services/security-engineering" className="dropdown-item">Security Engineering</Link>
                  </li>
                </ul>
              </li> */}
//               <li>
//                 <Link to="/contact-us">Contact Us</Link>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </div>
//   </nav>
// </> */}


export default Appbar
