import { leaderShipData } from "./data";


async function formatLeaderData() {
    try {
        const leaderData1 = leaderShipData.leadershipRow1;

        return [...leaderData1];
    } catch (err) {
        console.log({ message: err.message });
    }
}


export async function getLeaders() {
    try {
        const leaders = await formatLeaderData();
        return leaders;
    } catch (err) {
        console.log({ message: err.message });
    }
}


export async function getLeader(id) {
    try {
        const leaders = await formatLeaderData();
        const foundLeader = leaders.filter(l => l.leaderId === id);
        console.log(foundLeader);
        return foundLeader;
    } catch (err) {
        console.log({ message: err.message });
    }
}