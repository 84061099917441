import React from 'react';

export default function SecurityEngineering() {
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/engineer.jpg)',  
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative'
        }}
      >
        <div 
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Security Engineering</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</a></li>
                  <li className="breadcrumb-item"><a href="/our-services" style={{ color: '#fff', textDecoration: 'none' }}>Services</a></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Security Engineering</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="p-b-10" style={{ paddingBottom: '10px !important', minHeight: '10vh' }}>
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-3 pb-3 mx-auto">
              <div className="heading-text heading-section text-center" style={{ marginBottom: '30px'}}>
                <img className="icons img-fluid pb-4" src="/assets/images/code.svg" alt="audit" />
              </div>
            </div>
            <div className="col-lg-9">
              <h3 
                style={{ 
                  fontSize: '1.7857em',
                  lineHeight: '1.5em',
                  fontWeight: '500',
                  letterSpacing: '0.5px',
                  margin: 0,
                  color: '#1f1f1f',
                  marginBottom: '12px'
                }}
              >
                Security Engineering
              </h3>
              <span
                style={{
                  fontSize: '1.25rem',
                  fontWeight: '400',
                  color: '#484848',
                  lineHeight: '1.65714em'
                }}
              >
                Eget nunc scelerisque viverra mauris in. Nunc scelerisque viverra mauris in. Nibh mauris cursus mattis molestie a iaculis at. Maecenas ultricies mi eget mauris pharetra et ultrices. Tincidunt dui ut ornare lectus sit amet. Velit scelerisque in dictum non consectetur. Libero id faucibus nisl tincidunt eget nullam. Urna nunc id cursus metus aliquam eleifend mi in. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Volutpat sed cras ornare arcu dui vivamus arcu. Posuere urna nec tincidunt praesent. Id faucibus nisl tincidunt eget nullam. Ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Ac feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
