import React from 'react';
import { Link } from 'react-router-dom';

export default function InformationCard({ image, title, description, link, alt }) {
  return (
    <div className="card">
      <img src={`${image}`} className="card-img-top" style={{ backgroundSize: 'cover' }} alt={alt} />
      <div className="card-body">
        <h5 className="card-title" style={{ color: '#757575', textTransform: 'uppercase', fontWeight: 'bold'  }}>{title}</h5>
        <p style={{ color: '#757575', maxWidth: '100%', fontSize: '17px', lineHeight: '1.6' }}>{description}</p>
        <Link to={`${link}`} className="btn btn-dark">Learn More</Link>
      </div>
    </div>
  )
}
