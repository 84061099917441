import React from 'react';

import './imagecard.css';

function ImageCard({ src }) {
  return (
    <div 
      className="card" 
      style={{
        border: 0,
        borderRadius: 0,
        padding: '20px',
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }}
    >
      <a href="/" className="image-overlay-card">
        <img className="card-img-top image-overlay-child client" src={src} alt="clients" />
      </a>
    </div>
  )
}

export default ImageCard;