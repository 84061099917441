export const imageCardData = {
  image: [
    '/assets/images/dot2.png',
    '/assets/images/doe.png',
    '/assets/images/doe3.png',
    '/assets/images/eia.png',
    '/assets/images/doh.png',
    '/assets/images/cap2.png',
    '/assets/images/nih.png',
    '/assets/images/iom.png',
    '/assets/images/dva.png',
    '/assets/images/cfs2.png'
  ]
};

export const servicesData = {
  servicesPage: [
    {
      image: '/assets/images/analyze2.svg', 
      title: 'Auditing', 
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
      link: '/our-services/auditing',
      alt: 'audit'
    },
    {
      image: '/assets/images/test.svg', 
      title: 'Penetration Testing', 
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
      link: '/our-services/penetration-testing',
      alt: 'pentesting'
    },
    {
      image: '/assets/images/code.svg', 
      title: 'Security Engineering', 
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.', 
      link: '/our-services/security-engineering',
      alt: 'engineering'
    },
    {
      image: '/assets/images/forensic.svg', 
      title: 'Incident Response and Forensic Services', 
      description: 'Varius vel pharetra vel turpis nunc eget lorem dolor. Lorem ipsum dolor sit amet consectetur.', 
      link: '/our-services/incident-response-and-forensic-services',
      alt: 'footprint'
    },
    {
      image: '/assets/images/compliance.svg', 
      title: 'Compliance', 
      description: 'Aliquam eleifend mi in nulla posuere. A arcu cursus vitae congue mauris rhoncus.', 
      link: '/our-services/compliance',
      alt: 'compliance'
    },
    {
      image: '/assets/images/security.svg', 
      title: 'Security Management', 
      description: 'Aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Sagittis purus sit amet volutpat consequat mauris nunc congue.', 
      link: '/our-services/security-management',
      alt: 'management'
    },
  ]
};

export const leaderShipData = {
  leadershipRow1: [
    {
      leaderId: 'afua-enchill',
      name: 'Afua Enchill',
      position: 'Director of Cybersecurity Operations',
      description: 'As Director of Cybersecurity Operations and Health IT solution of Elite Consulting, Afua\'s role is to support organizations in maintaining the highest possible levels of confidentiality, data integrity, and system availability, of their systems and system components. As Director of Cybersecurity Operations and Health IT solutions, she brings more than 10 years of experience in health IT solutions and Cybersecurity Operations. Her extensive experience includes the Department of Health and Human Services, Walter Reed National Military Medical Center, D.C. Department of Health (DOH), and the International Organization for Migration. Afua has a background in biological sciences and earned her Master of Science in Regulatory Affairs in Biotechnology Regulatory Affairs Specialization from the University of Maryland. Afua is Certified in HIPAA Security, Security+, Certified Information Security Auditor (CISA), and Certified Information Security Management (CISM). Afua is an enthusiastic, results-driven professional that is eager to contribute to the successful implementation of cybersecurity and health solutions based on clients\' needs',
      linkedin: 'https://www.linkedin.com/in/afua-enchill-43b37b38/',
      email: 'farogold@outlook.com',
      image: '/assets/images/afua.jpeg',
      alt: 'afua-enchill'
    },
    {
      leaderId: 'alexandra-valentine',
      name: 'Alexandra Valentine',
      position: 'Senior Talent Acquisition Specialist',
      description: 'I am a passionate and results-driven talent acquisition specialist with a proven track record of identifying, engaging, and hiring top-tier talent. With over 10 years of experience in the industry, I have developed a keen eye for spotting exceptional candidates and a deep understanding of what it takes to attract and retain them. My approach to recruitment is rooted in building meaningful relationships with both candidates and hiring managers. By taking the time to understand the unique needs and goals of each party, I am able to effectively match talent with the right opportunities. Whether it\'s sourcing passive candidates or creating engaging employer branding initiatives, I am committed to delivering innovative and effective solutions to meet the needs of the organization. As a Senior Talent Acquisition Specialist, I am skilled in managing the entire recruitment lifecycle, from initial sourcing to final offer negotiations. My ability to navigate complex hiring processes and communicate effectively with stakeholders at all levels allows me to consistently exceed expectations and deliver high-quality talent on time and within budget. Above all, I am passionate about making a positive impact on the organizations I work with by helping them build strong, diverse teams that drive business success.',
      linkedin: '',
      email: '',
      image: '/assets/images/alex.jpg',
      alt: 'alexandra-valentine'
    },
    {
      leaderId: 'frenklin-kokoneshi',
      name: 'Frenklin Kokoneshi',
      position: 'Senior Consultant, Emerging Technologies',
      description: 'Multifaceted and results-driven professional with demonstrated history of managing all aspects of cyber security, risk, analyzing / leading business operations, and ensuring end-to-end solution for deliverance of desired business results in problem-solving and demanding environments.',
      point1: 'Instrumental in planning and executing client engagements with main focus on assessment, review, design, and deployment of cloud computing infrastructure.',
      point2: 'Recognized for directing entire project and program lifecycle processes and ensuring timely delivery, while complying with company\'s quality and regulatory standards.',
      point3: 'Proven ability to successfully analyze organization\'s critical business needs, identify deficiencies/opportunities, and develop innovative and cost-effective solutions to enhance competitiveness, increase revenues, and improve customer service offerings.',
      point4: 'Articulate communicator with extraordinary interpersonal, leadership, relationship-building, problem solving, and management skills.',
      point5: 'Technically proficient in AWS, Confluence, JIRA, FISMA, PCI-DSS, SOX, NIST, FedRamp, Tableau, R, SQL, Microsoft Office Suite, and Google Suite.',
      linkedin: '',
      email: '',
      image: '/assets/images/franklin.jpeg',
      alt: 'franklin-kokoneshi'
    },
    {
      leaderId: 'somadri-tiwari',
      name: 'Somadri Tiwari',
      position: 'Senior DevOps Cloud Engineer',
      description: 'As a Senior DevOps Cloud Engineer with over 11 years of experience, I am a seasoned professional in Cloud Platforms such as AWS, Azure, and GCP, as well as DevOps tools such as Docker, Jenkins, Github, Kubernetes, and Terraform. My expertise in scripting languages such as PowerShell, Bash Scripting, Python, and JSON allows me to automate complex tasks and streamline processes. Throughout my career, I have proven my ability to design, deploy, and maintain cloud infrastructure, as well as implement continuous integration and continuous delivery (CI/CD) pipelines. My proficiency in various DevOps methodologies, including Agile, Scrum, and Kanban, has enabled me to collaborate effectively with cross-functional teams and deliver high-quality software products. I have experience leading large-scale projects, providing technical guidance and mentoring junior engineers, and developing best practices and standards for DevOps processes. With a strong focus on scalability, security, and performance optimization, I am committed to delivering solutions that meet business requirements and exceed customer expectations. Overall, I am a versatile and results-driven DevOps Cloud Engineer who thrives in a dynamic, fast-paced environment. With a deep understanding of cloud infrastructure and DevOps principles, I am poised to make a significant contribution to any organization seeking to leverage the benefits of cloud computing and accelerate their software delivery pipeline.',
      linkedin: '',
      email: '',
      image: '/assets/images/somadri.jpeg',
      alt: 'somadri-tiwari'
    }
  ]
};