import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AnimatedText from 'react-animated-text-content';
import ScrollAnimation from 'react-animate-on-scroll';

export default function IntroSection() {
  return (
    <section className="intro-slide slide-0 text-content home-hero section-group done" id="slide0">
      <div className="video-holder">
        <video id="home-hero-video" className="per-video-vide0 fadein" playsInline src="/assets/videos/world-1992.mp4" loop="loop" muted width="300" height="150" autoPlay></video>
      </div>
      <div className="grid-container container">
        <Row className="inner-content">
          <Col md lg="8" className="cell txt-block">
            <div className="content-padding">
              <div className="inner-wrap">
                <AnimatedText
                  type="words"
                  animationType="lights"
                  animation={{
                    ease: 'ease-in',
                    scale: 1.1
                  }}
                  threshold={0.1}
                  duration={0.4}
                  tag="h1"
                >
                  Secure your critical infrastructure
                </AnimatedText>
                <div className="block-content">
                  <p className="block-text" style={{ color: '#fff' }}>
                    <AnimatedText
                    type="words"
                    animationType="lights"
                    animation={{
                      ease: 'ease-in',
                      scale: 1.1
                    }}
                    threshold={0.1}
                    duration={0.4}
                    tag="small"
                  >
                    Protect what matters most. 
                  </AnimatedText>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}
