import React from 'react';

export default function Compliance() {
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/gdpr.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative'
        }}
      >
        <div 
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Compliance</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</a></li>
                  <li className="breadcrumb-item"><a href="/our-services" style={{ color: '#fff', textDecoration: 'none' }}>Services</a></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Compliancce</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="p-b-10" style={{ paddingBottom: '10px !important', minHeight: '10vh' }}>
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-3 pb-3 mx-auto">
              <div className="heading-text heading-section text-center" style={{ marginBottom: '30px'}}>
                <img className="icons img-fluid pb-4" src="/assets/images/compliance.svg" alt="compliance" />
              </div>
            </div>
            <div className="col-lg-9">
              <h3 
                style={{ 
                  fontSize: '1.7857em',
                  lineHeight: '1.5em',
                  fontWeight: '500',
                  letterSpacing: '0.5px',
                  margin: 0,
                  color: '#1f1f1f',
                  marginBottom: '12px'
                }}
              >
                Compliance
              </h3>
              <span
                style={{
                  fontSize: '1.25rem',
                  fontWeight: '400',
                  color: '#484848',
                  lineHeight: '1.65714em'
                }}
              >
                Non tellus orci ac auctor. Fringilla ut morbi tincidunt augue interdum velit. Est placerat in egestas erat imperdiet. Congue eu consequat ac felis donec et odio. Libero justo laoreet sit amet cursus sit amet. Erat imperdiet sed euismod nisi porta lorem mollis aliquam. Magna sit amet purus gravida quis blandit turpis cursus in. Porttitor leo a diam sollicitudin tempor id. Non arcu risus quis varius quam quisque id diam vel. Tellus molestie nunc non blandit massa enim nec dui nunc. In hac habitasse platea dictumst quisque. Lorem ipsum dolor sit amet. Nibh tellus molestie nunc non blandit massa. At lectus urna duis convallis convallis tellus id interdum velit. Pharetra convallis posuere morbi leo urna molestie at elementum eu.
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
