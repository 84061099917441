import React from 'react';
import { Link } from 'react-router-dom';

export default function HorizontalCards({ image, title, description, link }) {
  return (
    <div className="card mb-6">
      <div className="row g-0">
        <div className="col-md-4" style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h2 className="card-title" style={{ color: '#757575', fontWeight: 'bold', textTransform: 'uppercase' }}>{title}</h2>
            <p className="card-text" style={{ color: '#757575', fontSize: '20px', lineHeight: '1.6' }}>{description}</p>
            <Link to={`${link}`} className="btn btn-dark">Learn More</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
