import React from 'react';
import { Link } from 'react-router-dom';

import './appfooter.css';

function AppFooter() {

  const getFullYear = () => {
    const currentYear = new Date()
    const year = currentYear.getFullYear();
    return year;
  };

  return (
    <footer id="footer" className="inverted">
      <div className="footer-content" style={{ textAlign: "center" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="widget-title-5" style={{ paddingBottom: "40px" }}>
              <img src="/assets/images/logo3.png" alt="footer-logo" width="250" />
              {/* <h5 style={{ color: '#ffff', fontWeight: '700', fontSize: '38px' }}>[Elite Consulting Logo Here]</h5> */}
            </div>
            <hr className="white" />
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="widget">
                <h4 style={{ color: '#ffff', fontWeight: '700', fontSize: '38px' }}>
                  Have a question?
                </h4>
                <div>
                  <p className="mb-3" style={{ color: '#ffff', fontSize: "20px" }}>For questions about services, capabilities, contracts, or to express a concern, contact us using the link below.</p>
                  <a href="/contact-us" className="btn btn-dark read-more-light">contact us</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg">
                  <h5 className="text-uppercase fw-bold mb-4">
                    <a href="/our-company/" className="footer-links" style={{ fontWeight: '700', fontSize: '38px' }}>
                      Our Company
                    </a>
                  </h5>  
                  <p>
                    <a href="/our-company/" className="sub-links" style={{ fontWeight: '700', fontSize: "20px" }}>About Our Company</a>
                  </p>
                  <p>
                    <a href="/our-company/leadership" className="sub-links" style={{ fontWeight: '700', fontSize: "20px" }}>Leadership</a>
                  </p>
                  {/* <p>
                    <a href="/our-company/prospective-clients" className="sub-links">Prospective Clients</a>
                  </p> */}
                </div>
                {/* <div className="col-lg-3">
                  <h5 className="text-uppercase fw-bold mb-4">
                    <a href="/our-services/" className="footer-links">
                      Our Services
                    </a>
                  </h5>
                  <p>
                    <a href="/our-services/security-management" className="sub-links">Security Management</a>
                  </p>
                  <p>
                    <a href="/our-services/compliance" className="sub-links">Compliance</a>
                  </p>
                  <p>
                    <a href="/our-services/incident-response-and-forensic-services" className="sub-links">Incident Response and Forensic Services</a>
                  </p>
                  <p>
                    <a href="/our-services/auditing" className="sub-links">Auditing</a>
                  </p>
                  <p>
                    <a href="/our-services/penetration-testing" className="sub-links">Penetration Testing</a>
                  </p>
                  <p>
                    <a href="/our-services/security-engineering" className="sub-links">Security Engineering</a>
                  </p>
                </div>
                <div className="col-lg-3">
                  <div className="widget">
                    <i className="fa-brands fa-linkedin fa-fw"></i>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-content">
        <div className="container">
          <div className="copyright-text text-center">
            Copyright © 

            <span id="copyright">
              {` ${getFullYear()} `}
            </span>

            Elite Consulting
          </div>
        </div>
      </div>
    </footer>
  )
}

export default AppFooter;