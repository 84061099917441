import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { getLeader } from '../data/leaders';

import './profile.css';


export async function leaderLoader({ params }) {
  const leader = await getLeader(params.leaderId);
  return { leader };
}


export default function LeaderProfile() {
  const { leader } = useLoaderData();
  
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/women.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div 
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
            <div className="container">
              <div className="about-page-title">
                <h1 className="pen-title">Leadership</h1>
              </div>
              <div className="breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/our-company/leadership" style={{ color: '#fff', textDecoration: 'none' }}>Leadership</Link></li>
                    {leader.map((l, k) => (
                      <li 
                        key={k}
                        className="breadcrumb-item active" 
                        aria-current="page" 
                        style={{ 
                          color: '#fff', 
                          fontWeight: 'bold' 
                        }}
                      >
                        {l.name}
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
            </div>
        </div>
      </section>

      <section id="page-content">
        <div className="container">
          {leader.length ? (
            <div className="row team-members-page m-b-40">
              {leader.map((l, k) => (
                <>
                  <div className="col-lg-4">
                    <div className="team-member-page">
                      <div className="team-image-page">
                        <img src={l.image} alt={l.alt} />
                      </div>
                      <div className="team-desc">
                        <div className="align-center pt-4">
                          <div className="align-center pt-4">
                            <Link className="btn btn-slide btn-light" to={l.linkedin}>
                              <i className="fab fa-linkedin-in fa-fw-l"></i>
                            </Link>

                            <a className="btn btn-slide btn-light" href={`mailto:${l.email}`}>
                              <i className="fa-regular fa-envelope fa-fw-l"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="heading-text heading-section">
                      <h2>{l.name}</h2>
                      <h4>{l.position}</h4>
                      <span className="profile-lead">{l.description}</span>
                      <br />
                      <br />
                      {l.point1 ? (
                        <ul style={{ fontWeight: 200 }}>
                          <li>{l.point1}</li>

                          <br />

                          <li>{l.point2}</li>

                          <br />
                          
                          <li>{l.point3}</li>

                          <br />

                          <li>{l.point4}</li>

                          <br />

                          <li>{l.point5}</li>
                        </ul>
                      ) : (
                        <br />
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <p>
              No Data
            </p>
          )}
        </div>
      </section>
    </>
  )
}
