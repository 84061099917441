import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { IconBox } from '../../../components';
import { servicesData } from '../../../data/data';

import './services.css';

export default function ServicesSection() {
  return (
    <>
      <section id="services-section" className="background-grey text-center">
        <div className="heading-text heading-section text-center pb-4">
          <ScrollAnimation
            animateIn="fadeInLeft"
            duration={2}
            animateOnce={true}>
              <h2>
                Our Services
              </h2>
              <p>How we can help</p>
            </ScrollAnimation>
        </div>
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            {servicesData.servicesPage.map((service, k) => (
              <div className="col-lg-3 col-sm-6">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  duration={1}
                  animateOnce={true}
                >
                  <IconBox 
                    src={service.image} 
                    title={service.title} 
                    alt={service.alt} 
                    link={service.link}
                  />
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
