import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {  
  Root, 
  Home, 
  Contact, 
  Services, 
  SecurityEngineering,
  Auditing,
  PenetrationTesting,
  Compliance,
  Company,
  Leadership,
  ForensicServices,
  SecurityManagement,
  LeaderProfile
} from './routes';
import { leaderLoader } from './routes/LeaderProfile';
import { rootLoader } from './routes/Root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <div>404 Error Here!</div>,
    loader: rootLoader,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/our-company/',
        element: <Company />
      },
      {
        path: '/our-company/leadership',
        element: <Leadership />
      },
      {
        path: '/our-company/leadership/leader-profile',
        element: <LeaderProfile />
      },
      {
        path: '/our-company/leadership/leader-profile/:leaderId',
        element: <LeaderProfile />,
        loader: leaderLoader,
      },
      {
        path: '/our-services/',
        element: <Services />,
      },
      {
        path: '/contact-us/',
        element: <Contact />
      },
      {
        path: '/our-services/auditing',
        element: <Auditing />
      },
      {
        path: '/our-services/penetration-testing',
        element: <PenetrationTesting />
      },
      {
        path: '/our-services/security-engineering',
        element: <SecurityEngineering />
      },
      {
        path: '/our-services/security-management',
        element: <SecurityManagement />
      },
      {
        path: '/our-services/incident-response-and-forensic-services',
        element: <ForensicServices />
      },
      {
        path: '/our-services/compliance',
        element: <Compliance />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
