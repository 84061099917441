import React from 'react';


export default function IconBox({ src, title, alt, link }) {
  return (
    <div className="icon-box effect large center process">
      <div className="col">
        <img src={src} alt={alt} className="icons img-fluid" style={{ width: "50%", margin: 20 }} />
        <h3 style={{ fontSize: "20px" }}>
          {title}
        </h3>
      </div>
    </div>
  )
}
