import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutCompanySection() {
  return (
    <section className="about-company-section">
      <div className="container about-container">
        <div className="row">
          <div className="col-lg-7 m-b-60">
            <h2 className="header-h2">
              <span className="logo-default">Welcome to Elite Consulting</span>
            </h2>
            <p className="lead">
            Elite Consulting (EC), head-quartered in White Plains, Maryland, is a Small Business Administration (SBA) Certified, Veteran, Women-Minority owned business. Elite Consulting offers a varied Cybersecurity management consulting services to both the private and federal sector. EC has the tools and expertise to help you achieve your security needs. We are committed to keeping our customers safe and secure, and guarantee a 100% satisfaction with our services.
            </p>

            <br />

            <Link 
              to="/our-company" 
              className="btn btn-dark read-more" 
              reloadDocument
              style={{ marginRight: "20px" }}
            >
              Read More
            </Link>
            <a href="https://www.fedlinks.com/profile/Elite-Consulting-LLC-White-Plains-MD\"><img class="fedlinks_badge" src="https://fedlinks.s3.amazonaws.com/fedlinks_badges/badges/000/006/174/medium/badge-fedlinks-seal-tag_2x.png?1699971471\" alt="Badge fedlinks seal tag 2x\" /></a>
          </div>
          <div className="col-lg-4 offset-1 m-t-40">
            <img src="/assets/images/about-home.svg" width="300" height="300" alt="about-img" />
          </div>
        </div>
      </div>
    </section>
  )
}
