import React from 'react';
import { Link } from 'react-router-dom';

import './company.css';

export default function Company() {
  return (
    <>
      <section 
        className="" 
        style={{ 
          backgroundImage: 'url(/assets/images/women.jpg)',  
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div 
          className="mask"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
            padding: '45px 45px 25px 45px'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">About Our Company</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>About Our Company</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="p-b-10">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-lg-12 pb-5">
              <div className="heading-company-text heading-company-section">
                <h4>Welcome to Elite Consulting</h4>
                <span className="about-company-lead">
                Elite Consulting (EC) is a Cyber Security Consulting firm whose mission is rooted in the three fundamental information security concepts known as the CIA triad i.e., Confidentiality, Integrity, and Availability. EC assesses organizations computer systems and system components, network, and software for vulnerabilities, then design and implement custom security solutions to fit the clients' needs and budget.
                </span>

                <br />
                <br />

                <span className="about-company-lead">
                More importantly, EC helps organizations develop a strategy to protect and defend their critical infrastructure, sensitive data, intellectual property, private information etc from Advanced Persistent Threats (APTs), malwares, hackers, and other cyber-attacks. Our information security services leverages compliance requirements set forth in security standards and frameworks such as The National Institute of Stands and Technology Cybersecurity Framework (NIST CSF), The General Data Protection Regulation (GDPR), The Sarbanes-Oxley Act of 2002 (SOX), The Health Insurance Portability and Accountability Act of 1996 (HIPAA or the Kennedy-Kassebaum Act), The Gramm-Leach-Bliley Act (GLBA), The International Organization for Standardization (ISO) etc.
                </span>

                <br />
                <br />
                <br />
                <br />

                <h4>What We Do</h4>
                <span className="about-company-lead">Elite Consulting, LLC provides cloud and on-premise cybersecurity services. We strive to ensure the highest security standards, protecting our customer's data and networks from malicious intruders. Our services are customized and tailored to meet the unique needs of our customers. Our primary offerings include:
                </span>

                <br />
                <br />

                <span className="about-company-lead">
                  <ul>
                    <li>Cloud and On-prem automation leveraging novel developments in DevOps.</li>
                    <li>Risk Assessments - examination of networks and infrastructures in order to identify and mitigate security risks</li>
                    <li>Security Audits - verification that appropriate security controls are in place to comply with internal policies and industry regulations</li>
                    <li>Vulnerability Analysis - testing of production systems to assess security exposures</li>
                    <li>Penetration Testing - simulating a real attack on an organization's systems in order to determine their defensive capabilities</li>
                    <li>Compliance Consulting - providing advice and guidance to ensure ongoing compliance with industry standards and regulations</li>
                  </ul>
                </span>
{/* 
                <br />
                <br /> */}

                {/* <h4>Custome Service</h4>
                <span className="about-company-lead">Our customer service has been lauded in the industry as one of the best, addressing customer needs promptly, answering customer questions accurately, and providing solutions that meet even the most complex requirements.
                </span> */}

                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
