import React from 'react';
import { Outlet } from 'react-router-dom';
import { Appbar, AppFooter } from '../components';
import { getLeaders } from '../data/leaders';

export async function rootLoader() {
  const leaders = await getLeaders();
  return { leaders };
}


export default function Root() {
  return (
    <>
      <div className="newheader">
        <Appbar />
      </div>
      <div id="detail">
        <Outlet />
      </div>
      <AppFooter />
    </>
  )
}
