import React from 'react';
import { Link } from 'react-router-dom';
import { IconBox } from '../components';
import { servicesData } from '../data/data';

import './services.css'

export default function Services() {
  return (
    <>
      <section 
        className="" 
        style={{ 
          backgroundImage: 'url(/assets/images/services2.jpg)',
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '45px 45px 25px 45px' }}>
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Our Services</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Our Services</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="services-page-section">
        <div className="container">
          <div className="row">
            {servicesData.servicesPage.map((service, k) => (
              <div className="col-lg-4" key={k}>
                <IconBox 
                  src={service.image} 
                  title={service.title} 
                  alt={service.alt} 
                  link={service.link}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
