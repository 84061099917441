import React from 'react';
import { Container } from 'react-bootstrap';
import { PastPerformanceSection, ServicesSection, AboutCompanySection, IntroSection } from '../pages';

import './home.css';

export default function Home() {
  return (
    <>
      {/* Main Page Content */}
      <div className="content">
        <Container fluid>
          <div className="inner-content">
            <main className="main" role="main">
              <article>
                <div className="entry-content" itemProp="text">
                  {/* Intro Section */}
                  <IntroSection />
                  {/* End of Intro Section */}
                  
                  {/* About Company Section */}
                  <AboutCompanySection />
                  {/* End of About Company Section */}

                  {/* Services section  */}
                  <ServicesSection />
                  {/* End of Services section */}

                  {/* Past Performance Section  */}
                  <PastPerformanceSection />
                  {/* End of Clients Section */}
                </div>
              </article>
            </main>
          </div>
        </Container>
      </div>
    </>
  )
}
