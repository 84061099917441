import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { ImageCard } from '../../../components';
import { imageCardData } from '../../../data/data';

import './clients.css';

export default function PastPerformance() {
  return (
    <>
      <section id="clients-section" className="text-center">
        <div className="heading-text heading-section text-center pb-4">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}>
            <h2>
              Our Past Perfomance
            </h2>
            <p>Our combined experience</p>
          </ScrollAnimation>
        </div>
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            {imageCardData.image.map((i, k) => (
              <div className="col-lg-3 col-sm-6 col-xs-6">
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                >
                  <ImageCard key={k} src={i} />
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
