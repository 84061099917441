import React from 'react';
import { Link } from 'react-router-dom';
import { ImageCard } from '../components';
import { imageCardData } from '../data/data';

export default function ProspectiveClients() {
  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/building.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div 
          className="mask"
          style={{
            padding: '45px 45px 25px 45px',
            backgroundColor: 'rgba(0,0,0,0.4)'
          }}
        >
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Prospective Clients</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Prospective Clients</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="page-content">
        <div className="container">
          <div className="heading-text heading-section text-center pb-4">
            <h2>
            Our Prospective Clients
            </h2>
            <p>Who we work with</p>
          </div>
          <div className="row">
            {imageCardData.image.map((i, k) => (
              <div className="col-lg-4">
                <ImageCard key={k} src={i} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <br />
      <br />
    </>
  )
}
