import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import validate from 'validate.js';
import './contact.css';


const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;


const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  company: {
    length: {
      maximum: 128
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300
    }
  },
  phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10
    }
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 30000
    }
  }
};


export default function Contact() {
  const contactForm = useRef();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, contactForm.current, PUBLIC_KEY)
      .then(res => console.log('SUCCESS!', res.status, res.text))
      .catch(err => console.log(`FAILED...${err.message}`));

    setFormState(formState => ({
      ...formState,
      isValid: false,
      values: {}, 
      touched: {},
      errors: {}
    }));

    // e.target.reset();
  };

  const handleChange = (e) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === 'checked' ? e.target.checked : e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  };

  const hasError = (field) => formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <>
      <section 
        style={{ 
          backgroundImage: 'url(/assets/images/buildings.jpg)', 
          clear: 'both', 
          color: '#fff', 
          backgroundSize: 'cover', 
          margin: 'auto', 
          position: 'relative' 
        }}
      >
        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '45px 45px 25px 45px' }}>
          <div className="container">
            <div className="about-page-title">
              <h1 className="pen-title">Contact Us</h1>
            </div>
            <div className="breadcrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ color: '#fff', fontWeight: 'bold' }}>Contact Us</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pb-5">
              <h3 className="text-uppercase">Have a Question?</h3>
              <p className="contact-text">For non-Elite Consulting employees with questions about capabilities or services, please complete the form below. Contact us at <a style={{ color: '#1E5A9C' }} href="mailto:eliteconsultingroup21@gmail.com"><i className="fa-regular fa-envelope"></i> eliteconsultingroup21@gmail.com</a></p>
              <div className="row m-t-40">
                <div className="col-lg-6">
                  <address style={{ fontSize: "16px" }}>
                    <strong>Elite Consulting</strong>

                    <br />

                    6960 Indian Head Hwy, #347

                    <br />

                    Bryans Road, Waldorf MD, 20616-9997

                    <br />

                    <abbr title="Phone" style={{ fontSize: '16px' }}>P: 301-751-7539</abbr>
                  </address>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form ref={contactForm} headers="application/json" id="contact-form" onSubmit={sendEmail}>
                <div className="form-group">
                  <label for="form_name">First Name *</label>
                  <input 
                    id="form_name"
                    name="firstName"
                    type="text"
                    className="form-control"
                    required="required"
                    data-error="First Name is required"
                    onChange={handleChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <label for="form_name">Last Name *</label>
                  <input 
                    id="form_lastname"
                    name="lastName"
                    type="text"
                    className="form-control"
                    required="required"
                    data-error="Last Name is required"
                    onChange={handleChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <label for="form_company">Company</label>
                  <input 
                    id="form_company"
                    name="company"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <label for="form_email">Email *</label>
                  <input 
                    id="form_email"
                    name="email"
                    type="email"
                    className="form-control"
                    required="required"
                    data-error="Valid email is required"
                    onChange={handleChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <label for="form_phone">Phone *</label>
                  <input 
                    id="form_phone"
                    name="phone"
                    type="tel"
                    className="form-control"
                    required="required"
                    data-error="Phone number is required"
                    onChange={handleChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <label for="form_message">Message *</label>
                  <textarea
                    id="form_message"
                    name="message"
                    rows="4"
                    className="form-control"
                    required
                    data-error="Message is required"
                    onChange={handleChange}
                  ></textarea>
                  <div className="help-block with-errors"></div>
                </div>
                <div>
                  <input type="submit" className="btn contact-btn" value="Send Message" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
